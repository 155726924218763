import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";

function Projects(props) {
  return (
    <div id="industriesWeServe" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2">
          <h2>Industries We Serve</h2>
        </div>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {props?.data?.map((project, index) => (
            <ProjectCard
              imgPath={project.image}
              technologyUsed={project.technologyUsed}
              isBlog={false}
              title={project.name}
              description={project.description}
              link={project.url}
            />
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Projects;
