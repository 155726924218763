import React from "react";

const BusinessStats = () => {
  return (
    <div className="container text-center my-5" style={{ marginBottom: 20, marginTop: 20 }}>
      <div className="col-md-8 col-md-offset-2" style={{ marginBottom: 20 }}>
        <h2>Helping Small Businesses</h2>
      </div>
      <div className="row">
        <div className="col-md-3">
          <img src="img/graph.png" alt="Experience" width="50" height="50" />
          <h4 className="mt-2">5+</h4>
          <p style={{ fontWeight: 'bold' }}>YEARS IN BUSINESS</p>
        </div>
        <div className="col-md-3">
          <img src="img/trophy.png" alt="Experience" width="50" height="50" />
          <h4 className="mt-2">4+</h4>
          <p style={{ fontWeight: 'bold' }}>CERTIFICATIONS</p>
        </div>
        <div className="col-md-3">
          <img src="img/user.png" alt="Experience" width="50" height="50" />
          <h4 className="mt-2">50+</h4>
          <p style={{ fontWeight: 'bold' }}>INSTALLATIONS</p>
        </div>
        <div className="col-md-3">
          <img src="img/transhumanism.png" alt="Experience" width="50" height="50" />
          <h4 className="mt-2">50+</h4>
          <p style={{ fontWeight: 'bold' }}>EXPERIENCE</p>
        </div>
      </div>
    </div>
  );
};

export default BusinessStats;
