import React from "react";
import Card from "react-bootstrap/Card";

function ProjectCards(props) {
  return (
    <div className="col-lg-12 col-md-6 wow fadeInUp mb-4" data-wow-delay="0.1s">
      <Card className="service-item rounded h-100">
        <div className="d-flex align-items-center">
          <h4 className="mb-0 ml-3 purple">{props.title}</h4>
        </div>
        <Card.Text className=" p-4" style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
      </Card>
    </div>
  );
}
export default ProjectCards;
